import React, { useEffect, useState } from 'react';
import { bool, func, string } from 'prop-types';
import classNames from 'classnames';
import { Col } from '@thd-olt-component-react/core-ui';
import { Input } from '@thd-olt-component-react/input';
import { usePresentation } from '../../../context/PresentationProvider';
import * as analytics from '../../../ratings-reviews-analytics';

export const Search = ({ noResults, onSearch, searchTerm }) => {
  const [searchPillText, setSearchPillText] = useState(searchTerm);
  const [searchText, setSearchText] = useState(searchTerm || '');
  const { useCondensedLayout } = usePresentation();
  const onSubmit = (event) => {
    event.preventDefault();
    setSearchPillText(searchText);
    analytics.track('event', {
      origin: 'ratings and reviews',
      location: 'search',
      keyword: searchText,
      eventType: 'text'
    });
  };

  useEffect(() => {
    if (!searchPillText) {
      onSearch(null);
      return;
    }
    onSearch(searchPillText);
  }, [searchPillText]);

  useEffect(() => {
    setSearchText(searchTerm || '');
    if (!searchTerm) {
      setSearchPillText(searchTerm);
    }
  }, [searchTerm]);

  const formClasses = classNames(
    'ratings-reviews__search-reviews-form',
    {
      'ratings-reviews__search-reviews-form--mobile': useCondensedLayout
    }
  );

  return (
    <div className="ratings-reviews__search">
      <Col className={formClasses}>
        <form onSubmit={onSubmit}>
          <Input
            placeholder="Search"
            id="search-input"
            value={searchText}
            onChange={(currentValue) => setSearchText(currentValue)}
          />
          <button
            id="search"
            aria-label="Search"
            className="review_search_button"
            type="submit"
          />
        </form>
      </Col>

    </div>
  );
};

Search.displayName = 'RatingsAndReviewsSearch';

Search.propTypes = {
  noResults: bool,
  onSearch: func.isRequired,
  searchTerm: string,
};

Search.defaultProps = {
  noResults: false,
  searchTerm: '',
};
