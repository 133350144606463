/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { number, oneOfType, string } from 'prop-types';
import classNames from 'classnames';
import { Button } from '@thd-olt-component-react/button';
import { Col, Loader, Row } from '@thd-olt-component-react/core-ui';
import { ReportReview } from './ReportReview';
import VotingHelper from './helper/VotingHelper';
import './Voting.styles.scss';

class Voting extends React.Component {
  static CookieUtils;

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    const { totalPositiveFeedbackCount, totalNegativeFeedbackCount } = props;
    this.state = {
      liked: false,
      noOfLikes: totalPositiveFeedbackCount,
      disliked: false,
      noOfDislikes: totalNegativeFeedbackCount,
      reported: false,
      userId: '',
      buttonText: 'Helpful?',
      disabled: false,
    };
  }

  async componentDidMount() {
    const { default: cookieUtils } = await import('cookie-utils');
    Voting.CookieUtils = cookieUtils;
    this.setUserId();
  }

  setUserId = () => {
    const thdUserId = Voting.CookieUtils.readBrowserCookie('THD_USER_ID');
    const { userId } = this.state;
    if (userId !== thdUserId) {
      this.setState({ userId: thdUserId });
    }
  };

  submitReport = (event) => {
    event.preventDefault();
    const { apiKey, hostName, reviewId } = this.props;
    VotingHelper.notifyBV(hostName, reviewId, 'inappropriate', '', '', apiKey);
    this.setState({
      reported: true
    });
  };

  setHelpfulResponse = () => {
    this.setState({ buttonText: 'Helpful' });
  }

  toggleLikeEvent = (event, type) => {
    event.preventDefault();
    const buttonText = <Loader active bttn />;
    const disabled = true;

    this.setState(() => {
      return {
        buttonText,
        disabled
      };
    });

    const { state } = this;
    const voteType = type === 'disliked' ? 'Negative' : 'Positive';
    const changeType = state[type] ? 'UNDO' : voteType;
    const { totalPositiveFeedbackCount, totalNegativeFeedbackCount } = this.props;

    this.setState((currentState) => {
      const noOfDislikes = type === 'disliked' && !currentState.disliked
        ? totalNegativeFeedbackCount + 1
        : totalNegativeFeedbackCount;
      const noOfLikes = type === 'liked' && !currentState.liked
        ? totalPositiveFeedbackCount + 1
        : totalPositiveFeedbackCount;
      const liked = type === 'liked' ? !currentState.liked : currentState.liked;
      const disliked = type === 'disliked' ? !currentState.disliked : currentState.disliked;
      return {
        liked,
        disliked,
        noOfDislikes,
        noOfLikes
      };
    });

    const { apiKey, hostName, reviewId } = this.props;
    const { userId } = this.state;
    VotingHelper.notifyBV(hostName, reviewId, 'helpfulness', changeType, '', apiKey, userId, this.setHelpfulResponse);
  };

  render() {
    const { reviewId, version } = this.props;
    const {
      disliked,
      liked,
      noOfDislikes,
      noOfLikes,
      reported,
      buttonText,
      disabled
    } = this.state;
    const likeClass = classNames({
      ratings_reviews__voting__activeLike: liked,
      ratings_reviews__voting__passiveLike: !liked,
      nocursor: disliked
    });

    const dislikeClass = classNames({
      ratings_reviews__voting__activeDislike: disliked,
      ratings_reviews__voting__passiveDislike: !disliked,
      nocursor: liked
    });

    const helpfulClass = classNames(
      'helpful-button',
      {
        disabled
      }
    );

    return (
      <div className="ratings_reviews__voting" id="voting">
        {
          version === 'v1' ? (
            <Row>
              <Col xs="5" className="voting__helpful_message"><b>Was this helpful?</b></Col>
              <Col xs="2">
                <button
                  aria-label="like"
                  className={likeClass}
                  id="like"
                  onClick={(event) => this.toggleLikeEvent(event, 'liked')}
                  title="Helpful"
                  type="button"
                />
                <span className="count">{noOfLikes}</span>
              </Col>
              <Col xs="2">
                <button
                  aria-label="like"
                  className={dislikeClass}
                  id="dislike"
                  onClick={(event) => this.toggleLikeEvent(event, 'disliked')}
                  title="Unhelpful"
                  type="button"
                />
                <span className="count">{noOfDislikes}</span>
              </Col>
              <Col xs="2">
                <ReportReview />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs="8" className="helpful-button-container">
                <Button
                  className={helpfulClass}
                  onClick={(event) => this.toggleLikeEvent(event, 'liked')}
                  title="Helpful"
                  type="button"
                  disabled={disabled}
                  inline
                  outline
                  small
                >
                  {buttonText}
                </Button>
                {noOfLikes > 0
                  && (
                    <span className="helpful-count">
                      {`${noOfLikes} found this review helpful`}
                    </span>
                  )}
              </Col>
              <Col xs="4" className="helpful-report">
                <ReportReview
                  submitReport={this.submitReport}
                  reported={reported}
                  reviewId={reviewId}
                  useText
                />
              </Col>
            </Row>
          )
        }
      </div>
    );
  }
}

Voting.displayName = 'RatingsAndReviewsVoting';

Voting.propTypes = {
  apiKey: string.isRequired,
  hostName: string.isRequired,
  reviewId: oneOfType([string, number]).isRequired,
  totalNegativeFeedbackCount: number,
  totalPositiveFeedbackCount: number,
  version: string
};

Voting.defaultProps = {
  totalNegativeFeedbackCount: 0,
  totalPositiveFeedbackCount: 0,
  version: 'v1'
};

export { Voting };
