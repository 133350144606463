// TODO: clean up and simplify this for desktop / mobile component use
import React, { useEffect, useReducer, useRef } from 'react';
import { bool, func, string } from 'prop-types';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { Button } from '@thd-olt-component-react/button';
import { Search, Sort } from '../Filter/components';
import { Filter } from '../Filter/Filter.component';
import { PagerDisplayText } from '../PagerDisplayText/PagerDisplayText';
import { Review } from '../Review/Review.component';
import { ReviewPager } from '../Paging/ReviewPager.component';
import { ReviewSentiments } from '../Filter/components/ReviewSentiments/ReviewSentiments.component';
import { stateReducer } from '../../reducer';
import { initialState } from '../../constants';
import { useProductReviews } from '../useProductReviews';
import { getProductReviews } from '../../helpers';
import { PresentationProvider } from '../../context/PresentationProvider';
import styles from './review-results.module.scss';

export const ReviewResults = (props) => {
  const {
    apiKey,
    condensed,
    itemId,
    onReviewsChange,
    prosAndCons,
    category,
  } = props;
  const persistedPageContext = useRef();
  const persistedStatistics = useRef({});
  const initialReviews = useRef({});
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const reviewData = useProductReviews({ itemId, ...state });
  const {
    averageRating, FilterSelected, loading, reviewModels = [], statistics, totalReviews
  } = reviewData || {};
  const {
    filters, pageContext, searchText, selectedSentiment, sortBy
  } = state;
  const hasProductData = averageRating || totalReviews;

  const sentimentChange = (sentiment) => dispatch({ type: 'sentiment', value: sentiment });
  const selected = selectedSentiment ? [selectedSentiment] : filters.filter((rf) => rf.checked);
  let productReviews = getProductReviews({
    reviewModels, initialReviews, itemId, searchText, selected
  });

  const noFallbacks = !(hasProductData || productReviews || persistedStatistics.current[itemId]);

  useEffect(() => {
    let pageChanged = false;
    if (!persistedPageContext.current) {
      persistedPageContext.current = state.pageContext;
    } else {
      pageChanged = persistedPageContext.current.currentPage !== state.pageContext.currentPage;
    }
    if (onReviewsChange) {
      // TODO: clean up
      let changedReviews = getProductReviews({
        reviewModels, initialReviews, itemId, searchText, selected
      });
      onReviewsChange({ ...state, ...reviewData, pageChanged, changedReviews });
    }
  }, [state, reviewData]);

  useEffect(() => {
    if (initialReviews.current && !initialReviews.current[itemId]) {
      initialReviews.current[itemId] = reviewModels;
    }
  }, [reviewModels]);

  if (loading && !persistedStatistics.current[itemId]) {
    return null;
  }

  if (noFallbacks) {
    return null;
  }

  const updatedTotalpage = selectedSentiment
    ? Math.ceil(selectedSentiment?.TotalResults / 10) || 1
    : statistics?.totalPages || 1;
  if (state.pageContext.totalPages !== updatedTotalpage) {
    dispatch({ type: 'totalPages', value: updatedTotalpage });
  }
  const { SearchText } = FilterSelected || {};
  if (persistedStatistics.current && !persistedStatistics.current[itemId]) {
    persistedStatistics.current[itemId] = statistics;
    initialReviews.current[itemId] = reviewModels;
  }
  const reviewStatistics = persistedStatistics.current && persistedStatistics.current[itemId]
    ? persistedStatistics.current[itemId]
    : statistics;
  const noResults = state.searchText && reviewModels.length === 0 && !loading;

  return (
    <div className={styles['review-results']}>
      <PresentationProvider useCondensedLayout={condensed}>
        {prosAndCons && (
          <ReviewSentiments
            itemId={itemId}
            selectedSentiment={selectedSentiment}
            onSentimentChange={sentimentChange}
          />
        )}
        <>
          <Row className={styles['review-results__search-and-sort']} id="search-and-sort">
            <Col
              xs={12}
              sm={12}
              md={3}
              lg={3}
              fallback={12}
              className={styles['ratings-and-reviews__search-and-filter']}
            >
              <Search
                onSearch={(searchTerm) => dispatch({ type: 'search', value: searchTerm })}
                searchTerm={state.searchText}
                noResults={noResults}
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={9}
              lg={9}
              fallback={12}
              className={styles['ratings-and-reviews__search-and-filter']}
            >
              <Filter
                onChange={(updatedFilters) => dispatch({ type: 'filter', value: updatedFilters })}
                ratingFilters={filters}
                reviewStatistics={reviewStatistics}
                selectedSentiment={selectedSentiment}
              />
            </Col>
          </Row>
          <Row className={styles['review-results__sort-and-filter']}>
            <Col xs={6} sm={6} md={3} lg={3} fallback={6}>
              <PagerDisplayText
                topPager
                pageContext={pageContext}
                loading={loading}
                statistics={statistics}
                showReviewsText
              />
            </Col>
            <Col xs={6} sm={6} md={2} lg={2} fallback={6}>
              <Sort onChange={(newSort) => dispatch({ type: 'sort', value: newSort })} sortBy={sortBy} />
            </Col>
            <Col xs={12} sm={12} md={7} lg={7} fallback={12} className={styles['ratings-reviews__pills-wrapper']}>
              {selected.length > 0
                && (
                  <>
                    {selected
                      .map((checkbox, checkboxIndex) => {
                        const pillText = checkbox.value === 'verified'
                          ? 'Verified Purchases'
                          : `${checkbox.value} Star`;
                        return (
                          <Button
                            key={checkboxIndex}
                            id={checkbox.id}
                            onClick={() => dispatch({ type: 'removeFilter', value: checkbox })}
                            filter
                          >
                            {selectedSentiment ? selectedSentiment.Feature : pillText}
                          </Button>
                        );
                      }
                      )}
                  </>
                )}
              {
                SearchText
                && (
                  <Button
                    key="search"
                    id="search"
                    onClick={() => dispatch({ type: 'search', value: null })}
                    filter
                  >
                    {noResults && SearchText ? 'Clear Search Term' : SearchText}
                  </Button>
                )
              }
            </Col>

          </Row>
        </>
        {(productReviews).map((review, reviewIndex) => {
          return (
            <Review
              review={review}
              id={reviewIndex}
              key={`review-${reviewIndex}`}
              searchTerm={SearchText}
              apiKey={apiKey}
            />
          );
        })}
        <Row>
          <Col xs="12" sm="12" md="12" className="pager-section">
            <div className={styles['review-results__pager']}>
              <ReviewPager
                category={category}
                itemId={itemId}
                reviewsPageContext={pageContext}
                onPageChanged={(newPageContext) => dispatch({ type: 'pageChange', value: newPageContext })}
              />
            </div>
            <Row className={styles['review-results__pager-summary']}>
              <PagerDisplayText
                pageContext={pageContext}
                statistics={statistics}
                loading={loading}
                showReviewsText
              />
            </Row>
          </Col>
        </Row>
      </PresentationProvider>
    </div>
  );
};

ReviewResults.displayName = 'ReviewResults';

ReviewResults.propTypes = {
  apiKey: string,
  category: string,
  condensed: bool,
  itemId: string.isRequired,
  prosAndCons: bool,
  onReviewsChange: func,
};

ReviewResults.defaultProps = {
  apiKey: '',
  category: 'product',
  condensed: false,
  prosAndCons: false,
  onReviewsChange: null,
};
