import React, { createContext, useContext } from 'react';
import { node, bool } from 'prop-types';

const PresentationContext = createContext({});
export const usePresentation = () => useContext(PresentationContext);

export const PresentationProvider = ({ useCondensedLayout, children }) => {
  return (
    <PresentationContext.Provider value={{ useCondensedLayout }}>
      { children }
    </PresentationContext.Provider>
  );
};

PresentationProvider.propTypes = {
  children: node.isRequired,
  useCondensedLayout: bool.isRequired
};
