import React, { useEffect } from 'react';
import { bool, func, string, element } from 'prop-types';
import { useStoreId } from '@thd-nucleus/experience-context';
import { useDataModel } from '@thd-nucleus/data-sources';
import { QuickViewDrawer } from './sub-components/quick-view-drawer/QuickViewDrawer';
import { dataModel } from './dataModel';
import './QuickView.scss';

export const QuickView = ({
  enableScroll,
  isOpen,
  onClose,
  itemId,
  fetch,
  showBackNavLink,
  footerElement,
  isRatingsOpen,
  paginateCarousel,
  openDetailsInNewTab
}) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('quick-view.ready'); }, []);

  const storeId = useStoreId();
  const { data } = useDataModel('product', {
    variables: {
      itemId,
      storeId
    },
    ssr: false,
    skip: !itemId || !fetch
  });

  if (!data?.product?.itemId) {
    return null;
  }

  return (
    <div className="quick-view" data-component="QuickView">
      <QuickViewDrawer
        enableScroll={enableScroll}
        isOpen={isOpen}
        onClose={onClose}
        product={data.product}
        showBackNavLink={showBackNavLink}
        footerElement={footerElement}
        isRatingsOpen={isRatingsOpen}
        paginateCarousel={paginateCarousel}
        openDetailsInNewTab={openDetailsInNewTab}
      />
    </div>
  );
};

QuickView.dataModel = dataModel;

QuickView.displayName = 'QuickView';

QuickView.propTypes = {
  enableScroll: bool,
  isOpen: bool.isRequired,
  onClose: func,
  itemId: string.isRequired,
  fetch: bool,
  showBackNavLink: bool,
  footerElement: element,
  isRatingsOpen: bool,
  paginateCarousel: bool,
  openDetailsInNewTab: bool
};

QuickView.defaultProps = {
  enableScroll: false,
  onClose: () => {},
  fetch: true,
  showBackNavLink: false,
  footerElement: null,
  isRatingsOpen: false,
  paginateCarousel: true,
  openDetailsInNewTab: false
};
