import React from 'react';
import { number, string } from 'prop-types';
import { Rating } from '@one-thd/sui-atomic-components';

export const RatingsAndReviewsMobileHeader = (props) => {
  const { average, total, recommendationPercentage } = props;
  return (
    <div className="ratings-and-reviews-overview__mobile-header">
      <div className="ratings-and-reviews-overview__mobile-rating-details">
        <span className="ratings-and-reviews-overview__mobile-average">{average} </span>
        <div className="ratings-and-reviews-overview__mobile-total-wrapper">
          <Rating value={average} color="brand" />
          <span className="ratings-and-reviews-overview__mobile-total">
            {`out of ${total} reviews`}
          </span>
        </div>
      </div>
      <div className="ratings-and-reviews-overview__mobile-recommend">
        <span className="u__bold">{recommendationPercentage}%</span>
        {' Recommend'}
      </div>
    </div>
  );
};

RatingsAndReviewsMobileHeader.propTypes = {
  average: number.isRequired,
  total: number.isRequired,
  recommendationPercentage: string
};

RatingsAndReviewsMobileHeader.defaultProps = {
  recommendationPercentage: ''
};

RatingsAndReviewsMobileHeader.displayName = 'RatingsAndReviewsMobileHeader';
